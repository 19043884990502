// WARNING: no polyfill enabled features usage in this file - except promises.
/*
global window, GLOBAL_PAGE, NodeList
 */

// polyfill for focus-visible
import 'focus-visible'

window.app = (() => {
  function loadPage() {
    if (!GLOBAL_PAGE) return
        import(/* webpackChunkName: "[request]" */ `./scripts/pages/${GLOBAL_PAGE}/index.js`)
  }

  class App {
    constructor() {
      // eslint-disable-next-line no-undef
      if (
        'Intl' in window
                && 'URL' in window
                && 'Map' in window
                && 'forEach' in NodeList.prototype
                && 'startsWith' in String.prototype
                && 'endsWith' in String.prototype
                && 'includes' in String.prototype
                && 'includes' in Array.prototype
                && 'assign' in Object
                && 'entries' in Object
                && 'keys' in Object
      ) {
        loadPage()
      } else {
                import(/* webpackChunkName: "polyfill" */ './scripts/polyfill.js').then(loadPage)
      }
    }
  }

  return new App()
})()
